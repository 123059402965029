import React, { useEffect, useState } from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import FixedImage from "../components/fixedImage";
import DentistSelector from "../components/dentistSelector";
import Seo from 'gatsby-plugin-wpgraphql-seo';

import * as styleSheet from "./team.module.scss"

const TeamTemplate = (props) => {

  const [ dentistsInOrder, setDentistsInOrder ] = useState([]);


	const {
		data: {
			teamPage,
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							mobileNavigationLogo
						} 
					}
				}

			},
			dentists: {
				nodes: dentists,
			},
			locations: {
				nodes: allLocations,
			},
			rightArrowYellow,
			arrowBlue,
		}
	} = props;


  useEffect( () => {
		dentists.sort(function(a, b){
			var keyA = a.ACFDentist.lastName,
				keyB = b.ACFDentist.lastName;
			if(keyA < keyB) return -1;
			if(keyA > keyB) return 1;
			return 0;
		});
    setDentistsInOrder(dentists)
	}, [dentists])


	return (
		<Layout>
			<Seo post={teamPage} />
			<div className={styleSheet.top}>
				<div className={styleSheet.left}>
					<div className={styleSheet.lefttop}>
						{mobileNavigationLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={mobileNavigationLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
						<h1 className="sr-only" dangerouslySetInnerHTML={{__html: teamPage.title}}></h1>
					</div>
					<div className={styleSheet.leftbottom}>
						<span className={styleSheet.teamheading}>Select a team member:</span>
						 { dentists && dentists.length > 0 && <DentistSelector dentists={dentistsInOrder} /> }
						<div className={styleSheet.teamlist}>
							<div className={styleSheet.inner}>
								<ul className={styleSheet.list}>
									{  dentistsInOrder && dentists.map((dentist, index) => {
										return (
											<li key={`lstloc-'${dentist.id}`} className={styleSheet.listitem}>
												<Link to={dentist.link} className={styleSheet.link}>
													<FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
													<span dangerouslySetInnerHTML={{__html: dentist.title }}></span>
												</Link>	
											</li>
										)
									}) }
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className={styleSheet.right}>
					{  dentists && dentists.map((dentist, index) => {

						const locations = allLocations.filter((location, index) => {

							if( location.ACFLocation && location.ACFLocation && location.ACFLocation.locationInfo.left.dentists ){
								const dentistIDs = location.ACFLocation.locationInfo.left.dentists.map(ortho =>
									`${ortho.id}`,
								);
					
								return dentistIDs && dentistIDs.includes(dentist.id);
							} else {
								return null;
							}
						});

						return (
							<div key={dentist.id} className={styleSheet.dentist} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
								{ dentist.ACFDentist && dentist.ACFDentist.headshot && <Link to={dentist.link}><FluidImage image={dentist.ACFDentist.headshot} passedClass={styleSheet.image} /></Link> }
								{ dentist.title && dentist.link && <Link to={dentist.link} className={styleSheet.name} dangerouslySetInnerHTML={{__html: dentist.title}}></Link> }
								{ dentist.link && <Link to={dentist.link} className={styleSheet.view}>View profile <FixedImage image={rightArrowYellow} passedClass={styleSheet.icon} /></Link> }
								{ locations && locations.map( (location, index) => {
									return <Link key={location.id} className={styleSheet.locationlink} to={location.link} dangerouslySetInnerHTML={{__html: location.title}} />
								})}
							</div>
						)
					}) }
				</div>
			</div>

		</Layout>
	);
};

export const query = graphql`query ($id: String!) {
  teamPage: wpPage(id: {eq: $id}) {
    id
    title
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      canonical
      cornerstone
      schema {
        articleType
        pageType
        raw
      }
    }
  }
  dentists: allWpOrthodontist {
    nodes {
      title
      link
			ACFDentist {
				lastName
				headshot {
					localFile {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
				}
			}
      id
    }
  }
  options: wp {
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          mobileNavigationLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, layout: CONSTRAINED, quality: 100)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
  locations: allWpLocation {
    nodes {
      title
      id
      link
      ACFLocation {
        locationInfo {
          left {
            dentists {
              ... on WpOrthodontist {
                id
              }
            }
          }
        }
      }
    }
  }
  rightArrowYellow: file(name: {eq: "arrow"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  arrowBlue: file(name: {eq: "arrow_blue"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
}
`;

export default TeamTemplate;
